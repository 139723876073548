<template>
    <div class="v-modal change-staff-modal">
        <div class="change-staff-modal__wrapper">
            <button
                type="button"
                class="close-modal change-staff-modal__close"
                @click="modalClose"
            >×</button>

            <div class="modal-header" v-if="user_id">
                <h3 class="block-title">
                    {{ userInfo.first_name }} {{userInfo.middle_name }} {{ userInfo.last_name }}
                </h3>
            </div>
            <div class="modal-header" v-else>
                <h3 class="block-title">
                    Добавление сотрудника
                </h3>
            </div>
            <div class="change-staff-modal__tabs">
                <div
                    class="change-staff-modal__tabs-name"
                    @click="isVisibleInfo = true"
                    v-bind:class="{ 'change-staff-modal__tabs-name_active': isVisibleInfo }"
                >
                    Основная информация
                </div>
            </div>
            <div class="change-staff-modal__content">
                <ValidationObserver tag="form" @submit.prevent="onSubmit" ref="observer">
                    <div class="change-staff-modal__tab" :class="{ 'd-none': !isVisibleInfo }">
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <ValidationProvider vid="first_name" rules="required|max_length:255" v-slot="provider">
                                    <div class="form-group" v-bind:class="{'is-invalid': provider.errors.length > 0}">
                                        <label for="id_first_name">Имя *</label>
                                        <input
                                            type="text"
                                            id="id_first_name"
                                            name="first_name"
                                            class="form-control"
                                            v-model="userInfo.first_name"
                                        >
                                        <div id="id_first_name-error" class="invalid-feedback animated fadeInDown"
                                             v-if="provider.errors.length > 0">{{provider.errors[0]}}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-md-4">
                                <ValidationProvider vid="last_name" rules="required|max_length:255" v-slot="provider">
                                    <div class="form-group" v-bind:class="{'is-invalid': provider.errors.length > 0}">
                                        <label for="id_last_name">Фамилия *</label>
                                        <input
                                            type="text"
                                            id="id_last_name"
                                            name="last_name"
                                            class="form-control"
                                            v-model="userInfo.last_name"
                                        >
                                        <div id="id_last_name-error" class="invalid-feedback animated fadeInDown"
                                             v-if="provider.errors.length > 0">{{provider.errors[0]}}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-md-4">
                                <ValidationProvider vid="middle_name" rules="max_length:255" v-slot="provider">
                                    <div class="form-group" v-bind:class="{'is-invalid': provider.errors.length > 0}">
                                        <label for="id_middle_name">Отчество</label>
                                        <input
                                            type="text"
                                            id="id_middle_name"
                                            name="middle_name"
                                            class="form-control"
                                            v-model="userInfo.middle_name"
                                        >
                                        <div id="id_middle_name-error" class="invalid-feedback animated fadeInDown"
                                             v-if="provider.errors.length > 0">{{provider.errors[0]}}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <ValidationProvider vid="position" rules="required" v-slot="provider">
                                    <div class="form-group" v-bind:class="{'is-invalid': provider.errors.length > 0}">
                                        <label for="id_position">Должность *</label>
                                        <vue-bootstrap-typeahead
                                            id="id_position"
                                            name="position"
                                            v-model="userInfo.position"
                                            :data="positions"
                                            :serializer="s => s.name"
                                            ref="positionTypeahead"
                                        />
                                        <div id="id_position-error" class="invalid-feedback animated fadeInDown"
                                             v-if="provider.errors.length > 0">{{provider.errors[0]}}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="form-group col-12 col-md-4">
                                <label for="id_role">Роль на портале</label>
                                <v-select2
                                    id="id_role"
                                    name="role"
                                    label="name"
                                    :clearable="false"
                                    :searchable="false"
                                    :reduce="name => name.id"
                                    :options="roles"
                                    v-model="userInfo.role"
                                >
                                </v-select2>
                            </div>
                            <div class="col-12 col-md-4">
                                <ValidationProvider vid="email" rules="required|email" v-slot="provider"
                                                    name="Некорректный E-mail">
                                    <div class="form-group" v-bind:class="{'is-invalid': provider.errors.length > 0}">
                                        <label for="id_email">E-mail *</label>
                                        <input
                                            type="text"
                                            id="id_email"
                                            name="email"
                                            class="form-control"
                                            v-model="userInfo.email"
                                            :disabled="user_id"
                                        >
                                        <div id="id_email-error" class="invalid-feedback animated fadeInDown"
                                             v-if="provider.errors.length > 0">{{provider.errors[0]}}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <ValidationProvider vid="structure" rules="required" v-slot="provider">
                                    <div class="form-group" v-bind:class="{'is-invalid': provider.errors.length > 0}">
                                        <label for="id_structure">Отдел *</label>
                                        <v-select2
                                            id="id_structure"
                                            name="structure"
                                            selectedLabel="Удалить"
                                            v-model="userInfo.structure"
                                            :options="structures"
                                            :reduce="name => name.id"
                                            label="name"
                                            multiple
                                        >
                                            <span slot="no-options">Ничего не найдено</span>
                                        </v-select2>
                                        <div id="id_structure-error" class="invalid-feedback animated fadeInDown"
                                             v-if="provider.errors.length > 0">{{provider.errors[0]}}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-12 col-md-6">
                                <label for="id_phone_number">Номер телефона</label>
                                <vue-phone-number-input
                                    v-model="userInfo.phone_number"
                                    id="id_phone_number"
                                    :translations="translations"
                                    default-country-code="RU"
                                />
                            </div>
                            <div class="form-group col-12 col-md-6">
                                <label for="id_extension_code">Добавочный код</label>
                                <input
                                    type="text"
                                    id="id_extension_code"
                                    name="extension_code"
                                    class="form-control"
                                    v-mask="{regex: '^([0-9]*)?$', greedy: false}"
                                    v-model="userInfo.extension_code"
                                >
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-12 col-md-4">
                                <label>Начало работы</label>
                                <VueDatePicker
                                    class="form-control"
                                    color="#8e8e8e"
                                    :validate="true"
                                    :noHeader="true"
                                    :noCalendarIcon="true"
                                    :locale="{lang: 'ru'}"
                                    :format="'DD MMM YYYY'"
                                    placeholder=""
                                    :clearable="true"
                                    v-model="userInfo.start_date" />
                            </div>
                            <div class="form-group col-12 col-md-4">
                                <label for="id_mentors">Наставник</label>
                                <v-select2
                                    id="id_mentors"
                                    name="mentors"
                                    v-model="userInfo.mentors"
                                    :options="mentorsList"
                                    :reduce="full_name => full_name.id"
                                    label="full_name"
                                    :clearable="false"
                                    :searchable="true"
                                >
                                    <span slot="no-options">Ничего не найдено</span>
                                </v-select2>
                            </div>
                            <div class="form-group floating col-12 col-md-4 ml-auto d-flex align-items-end">
                                <label class="css-control">
                                    <input
                                        :disabled="user_id && current_user.id === user_id"
                                        type="checkbox"
                                        name="is_active"
                                        class="css-control-input"
                                        v-model="userInfo.is_active"
                                    >
                                    <span class="css-control-indicator"></span> Работает
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row justify-content-center mt-20 mb-0">
                        <div class="col-auto">
                            <button
                                type="button"
                                class="btn btn-rounded btn-noborder btn-outline-secondary"
                                @click="modalClose"
                            >
                                Отмена
                            </button>
                        </div>
                        <div class="col-auto">
                            <button
                                type="submit"
                                class="btn btn-rounded btn-primary-dark min-width-125"
                                @click="onSubmit"
                                :disabled="isSaving"
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>

<script>
    import session from '@/api/session'
    import { mapState } from 'vuex'
    import MaskedInput from 'vue-masked-input'
    import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
    import { REMOVE_USER_EDITOR } from '../../../store/types'

    export default {
        name: 'ChangeStaffModal',
        components: {
            MaskedInput,
            VueBootstrapTypeahead
        },
        props: {
            user_id: {
                default: null,
                type: Number
            }
        },
        data() {
            return {
                isVisibleInfo: true,
                isSaving: false,
                userInfo: {
                    email: '',
                    extension_code: '',
                    first_name: '',
                    id: null,
                    is_active: true,
                    is_owner: false,
                    last_name: '',
                    middle_name: '',
                    phone_number: '',
                    position: '',
                    role: 2,
                    start_date: null,
                    mentors: null,
                    structure: []
                },
                structures: [],
                positions: [],
                mentorsList: [],
                excess_user_count: false,
                translations: {
                    countrySelectorLabel: 'Код',
                    countrySelectorError: 'Выберите страну',
                    phoneNumberLabel: 'Номер телефона',
                    example: 'Пример :'
                }
            }
        },
        computed: {
            ...mapState('default_data', ['module', 'current_user']),
            ...mapState('users', ['usersEditorList']),
            roles() {
                const rolesFormatter = []
                if (this.userInfo.is_owner) {
                    rolesFormatter.push({ id: 0, name: 'Владелец портала' })
                } else {
                    if (this.current_user.is_owner) {
                        rolesFormatter.push({ id: 0, name: 'Владелец портала' })
                    }
                    rolesFormatter.push(
                        { id: 1, name: 'Администратор' },
                        { id: 2, name: 'Сотрудник' },
                        { id: 3, name: 'Редактор' }
                    )
                }
                return rolesFormatter
            }
        },
        watch: {
            'userInfo.role': {
                handler: function(val) {
                    if (val === 0 && this.current_user.is_owner && this.current_user.id !== this.userInfo.id) {
                        this.$swal({
                            title: 'Вы потеряете права владельца!',
                            icon: 'warning',
                            showConfirmButton: false,
                            timer: 1400
                        })
                    } else if (val !== 0 && this.current_user.is_owner && this.current_user.id === this.userInfo.id) {
                        this.$swal({
                            title: 'Вы потеряете права владельца!',
                            icon: 'warning',
                            showConfirmButton: false,
                            timer: 1400
                        })
                    }
                },
                deep: true
            },
            'userInfo.phone_number': {
                handler: function(val) {
                    if (val === null) {
                        this.userInfo.phone_number = ''
                    }
                },
                deep: true
            }
        },
        created() {
            session
                .get('/api/v1/structure/select/')
                .then(response => {
                    this.structures = response.data
                })
                .catch(() => {
                })
            session
                .get('/api/v1/users/positions/')
                .then(response => {
                    this.positions = response.data
                })
                .catch(() => {
                })
            session
                .get('/api/v1/staff/mentors/')
                .then(response => {
                    this.mentorsList = response.data
                })
                .catch(() => {
                })
            if (this.user_id) {
                session
                    .get(`/api/v1/staff/${this.user_id}/`)
                    .then(response => {
                        this.userInfo = response.data
                        this.$refs.positionTypeahead.inputValue = this.userInfo.position

                        if (this.usersEditorList.length > 0) {
                            const isRoleEditor = this.usersEditorList.find((user) => user.user === parseInt(this.userInfo.id))

                            if (isRoleEditor !== undefined) {
                                this.userInfo.role = 3
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            }
            if (!this.user_id) {
                session
                    .get('/api/v1/rate/current_rate/')
                    .then(response => {
                        this.excess_user_count = response.data.excess_user_count
                    })
                    .catch(() => {
                    })
            }
        },
        methods: {
            async onSubmit(e) {
                e.preventDefault()
                const isValidModal = await this.$refs.observer.validate()

                if (!isValidModal) {
                    this.isVisibleInfo = true
                }

                if (!this.userInfo.start_date) {
                    this.userInfo.start_date = null
                }

                if (isValidModal) {
                    const userInfoData = JSON.parse(JSON.stringify(this.userInfo))

                    this.isSaving = true

                    /*
                        Небольшой костыль:
                        "Меняем статус роли «Редактора» на «Сотрудника» и
                        запишем его в отдельный список редакторов или наоборот,
                        если изменили статус с «Редактора» на что то другое,
                        то удалим его из списка."
                    */
                    try {
                        const statusEditor = this.usersEditorList.find((user) => user.user === this.user_id)
                        const dataEditor = {
                            user: this.user_id,
                            excludes: []
                        }

                        if (userInfoData.role === 3) {
                            userInfoData.role = 2

                            if (statusEditor === undefined) {
                                await this.$store.dispatch('users/addUserEditor', dataEditor)
                            }
                        } else {
                            if (statusEditor !== undefined) {
                                await this.$store.dispatch('users/removeUserEditor', dataEditor)
                            }
                        }
                    } catch (error) {
                        console.error(error)
                    }

                    if (this.user_id) {
                        try {
                            const request = await session.put(`/api/v1/staff/${this.user_id}/`, userInfoData)
                            this.$modal.hide('change-staff-modal', { user: request.data })
                            this.$swal({
                                title: 'Отлично!',
                                text: 'Данные изменены',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1400
                            })
                        } catch (error) {
                            this.errorHandler(error)
                        }
                    } else {
                        try {
                            const request = await session.post('/api/v1/staff/', this.userInfo)
                            this.$modal.hide('change-staff-modal', { user: request.data })
                            this.$swal({
                                title: 'Отлично!',
                                text: 'Пользователь добавлен',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1400
                            })
                        } catch (error) {
                            this.errorHandler(error)
                        }
                    }
                }
            },
            errorHandler(error) {
                console.log('error', error)
                this.isSaving = false
                if (error.response) {
                    this.$refs.observer.setErrors(error.response.data)
                }
                this.$swal({
                    title: 'Ошибка!',
                    text: 'При обработке запроса произошла ошибка на сервере',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1400
                })
            },
            modalClose() {
                this.$emit('close')
            }
        }
    }
</script>

<style lang="sass">
    @import "#sass/v-style"
    .v--modal-overlay[data-modal="change-staff-modal"]
        z-index: 1032
        background-color: rgba(7, 35, 58, .5)
        @media (min-width: 768px)
            padding-top: 8px
            padding-bottom: 25px

        .v--modal-box
            overflow: visible

    .change-staff-modal
        &__wrapper
            padding: 20px 0

        &__tabs
            display: flex
            padding: 0 20px
            border-bottom: 1px solid #e8e8e8

            &-name
                margin-bottom: -1px
                margin-right: 50px
                padding: 15px 0
                color: #999999
                font-size: 1rem
                line-height: 1
                font-weight: 400
                text-transform: uppercase
                letter-spacing: 1.12px
                cursor: pointer

                &:last-of-type
                    margin-right: 0

                &_active
                    font-weight: 500
                    color: #000
                    border-bottom: 2px solid #fcc142

        &__close
            @media (min-width: 860px)
                left: 100%
                top: 0
                color: #FFFFFF

        &__content
            padding: 20px 20px 0

        &__excess
            padding: 10px
            text-align: center
            background: #dcdcdc
            font-size: 14px
            color: #000000

        .css-control-indicator
            width: 18px
            height: 18px
            border-radius: 2px
            border: 1px solid #e9e9e9
            background-color: #ffffff

        .css-control-input:checked ~ .css-control-indicator:after
            top: -1px
            left: 3px
            width: 17px
            height: 9px
            border-left: 2px solid #fcc142
            border-bottom: 2px solid #fcc142
            transform: rotate(-45deg)

        .vs__dropdown-menu
            padding: 0
            border-color: #e9e9e9

        .vs__dropdown-toggle
            min-height: 38px
            max-height: none
            border: 1px solid #e9e9e9

        .is-invalid .vs__dropdown-toggle
            border-color: #ef5350

        .vs__clear
            padding-bottom: 4px

        .vs__selected
            z-index: 500
            position: relative
            line-height: 26px

        .vs__dropdown-option
            padding: 4px 14px

            &--selected
                color: #575757
                background-color: #ddd

            &--highlight
                color: #161725
                background-color: #fbcf54
                border-color: #fbcf54

        .list-group-item
            padding: 4px 14px

            &.active
                color: #161725
                background-color: #fbcf54
                border-color: #fbcf54

        .vbt-autcomplete-list
            margin-top: -2px
            padding-top: 0

            strong
                font-weight: 400
</style>
<style lang="scss">
    .input-tel__input,
    .country-selector__input {
        border-color: rgb(212, 218, 227) !important;
    }

    .input-phone-number.is-focused .input-tel__input,
    .input-country-selector.is-focused .country-selector__input {
        box-shadow: none !important;
        border-color: #97a5ba !important;
    }

    .input-tel.lg .input-tel__input,
    .country-selector.lg .country-selector__input {
        height: 40px !important;
        min-height: 40px !important;
    }
</style>
